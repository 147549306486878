<template>
  <div
    class="card tasklist-item"
    v-if="!isEditing"
    @click.prevent="startEditing"
  >
    <!-- <div class="card tasklist-item" v-if="!isEditing" > -->
    <div class="card-body">
      <div
        :class="[
          isNewItem
            ? 'text-center text-dark font-weight-bold disable-select'
            : 'text-dark disable-select',
        ]"
      >
        <span v-if="!isNewItem"> {{ item.text || item.name }} </span>
          <span v-else> Add item </span>
      </div>
    </div>
  </div>

  <div class="card" v-else>
    <div class="card-body">
      <form class="form">
        <div class="form-group">
          <textarea
            name="itemDetails"
            rows="3"
            class="form-control"
            v-model.trim="form.text"
            data-vv-as="Item Details"
            placeholder="Your item description"
          ></textarea>
        </div>

        <div
          :class="[
            isNewItem ? 'text-center' : 'd-flex justify-content-between',
            'form-group',
          ]"
        >
          <div>
            <button
              class="btn btn-outline-secondary btn-sm mr-2"
              @click.prevent="save"
            >
              Save
            </button>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click.prevent="cancel"
            >
              Cancel
            </button>
          </div>
          <div v-show="!isNewItem">
            <button class="btn btn-sm text-danger" @click.prevent="remove">
              Delete
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Popup from "@/components/ProjectPopup/Popup.vue";

export default {
  props: ["item", "list", "board"],
  components: {
    Popup,
  },
  computed: {
    isNewItem() {
      return this.item.id == "";
    },
    displayText() {
      return this.isNewItem ? "+ New Item" : this.item.text;
    },
  },
  data() {
    return {
      active: false,
      isEditing: false,
      form: {
        id: "",
        text: "",
      },
    };
  },
  methods: {
    ...mapActions({
      saveTaskListItem: "saveTaskListItem",
      deleteTaskListItem: "deleteTaskListItem",
    }),
    startEditing() {
      if (this.isNewItem) {
        this.form.id = "";
        this.form.text = "";
        this.isEditing = true;
        this.$emit("item-editing");
      }
    },
    clearForm() {
      this.form.id = "";
      this.form.text = "";
    },
    save() {
      const updatedItem = {
        text: this.form.text,
      };
      this.saveTaskListItem({
        boardId: this.board._id,
        listId: this.list._id,
        item: updatedItem,
      });
      this.isEditing = false;
      this.$emit("item-edited");
    },
    cancel() {
      this.isEditing = false;
      this.$emit("item-cancelled");
    },
    remove() {
      this.deleteTaskListItem({
        boardId: this.board.id,
        listId: this.list.id,
        item: this.item,
      });
      this.$emit("item-deleted");
    },
  },
};
</script>