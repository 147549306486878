import { mapActions, mapGetters } from "vuex"
import {
  BModal, BButton, BFormFile,
  BForm,
  BFormGroup,
  BFormInput,} from 'bootstrap-vue'
import Checklist from '../Checklist/Checklist'
import UploadImages from "vue-upload-drop-images"
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import axios from "axios";

export default {
  components: {
    BModal,
    BButton,
    Checklist,
    UploadImages,
    vSelect,
    BFormFile,
    quillEditor,
    BForm,
    BFormGroup,
    BFormInput
  },
  props: ["item", "list", "board", 'users'],
  computed: {
    isNewItem() {
      return this.item._id == ""
    },
    displayText() {
      return this.isNewItem ? "+ New Item" : this.item.text
    },

  },
  mounted(){
    setTimeout(() => {
      this.userSelectedMethods()
      console.log('users', this.users);
      console.log('board', this.getBoard());
      console.log('board2', this.board);
      console.log('item', this.item);
      console.log('list', this.list);
    } , 1000)
    
  },
  created() {
    this.getFriends()
  },
  data() {
    return {
      userSelectedValue:null,
      editorOption : {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "Write your description",
      },
      active: false,
      isEditing: true,
      form: {
        _id: "",
        text: ""
      },
      Assigned: [],
      userSelected:[]
    }
  },
  methods: {
    userSelectedMethods() {
      console.log('item here', this.item,this.$props.item);
      if (this.item && this.item.assignedTo) {
        if (this.item.assignedTo.length > 0 && this.item.assignedTo[0] !== null) {
          this.userSelectedValue = {
            name: this.item.assignedTo[0].name,
            id: this.item.assignedTo[0]._id,
          }
        }
      }
    },
    getFriends() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/users?id=" +
          JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          response.data.data.friendList.forEach((element) => {
            this.Assigned.push({
              name: element.name,
              id: element._id,
            });
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    ...mapActions({
      saveTaskListItemTodo: "saveTaskListItemTodo",
      deleteTaskListItem: "deleteTaskListItem",
      updateTaskListItem:"updateTaskListItem"
    }),
    ...mapGetters({
      getBoard: 'getBoard'
    }),
    startEditing() {
      this.isEditing = true;
      this.$emit("item-editing");
    },
    clearForm() {
      this.form._id = "";
      this.form.text = "";
    },
    save() {
      const { _id, ...updatedItem }= this.item
      this.saveTaskListItemTodo({
        boardId: this.board,
        listId: this.list._id,
        itemId:this.item._id,
        item: updatedItem
      });
      this.isEditing = false;
      this.$emit("item-edited");

    },
    saveItem() {
      // const { _id, ...updatedItem } = this.item
      // console.log(this.item,this.userSelectedValue)
      this.item.assignedTo=[]
      if(this.userSelectedValue!==null){
        this.item.assignedTo.push(this.userSelectedValue.id)
      }
      var itemId = this.item._id
      if(this.item._id){
        delete this.item._id;
      }
      console.log("here item", this.board,this.list)
      this.updateTaskListItem({
        boardId: this.board,
        listId: this.list._id,
        itemId: itemId,
        item: this.item
      });
      this.isEditing = false;
      this.$emit("item-cancelled");
      this.hideModal()
    },
    hideModal() {
      this.$refs['task-item-board'].hide()
    },
    cancel() {
      this.isEditing = false
      this.$emit("item-cancelled")
    },
    remove() {
      this.deleteTaskListItem({
        boardId: this.board,
        listId: this.list._id,
        item: this.item
      })
      this.$emit("item-deleted")
    }
  }

}