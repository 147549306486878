<template>
  <div>
    <AppHeader></AppHeader>
    <div class="projets">
      <draggable
        v-model="this.getBoard.lists"
        class="row flex-nowrap mt-1 phone-ver display-none"
        @change="changeOrder($event)"
      >
        <!-- @SelectItem="OnSelection($event)" -->
        <!-- v-for="(listItem) in lists" -->
        <TaskList
          @SelectItem="OnSelection($event, getBoard, getBoard.lists, index)"
          v-for="(listItem, index) in this.getBoard.lists"
          :key="index"
          :board="getBoard"
          :list="listItem"
        ></TaskList>
      </draggable>
      <div class="display-responsive-none">
        <div class="row flex-nowrap mt-1 phone-ver">
          <!-- @SelectItem="OnSelection($event)" -->
          <!-- v-for="(listItem) in lists" -->
          <TaskList
            @SelectItem="OnSelection($event,getBoard, getBoard.lists, index)"
            v-for="(listItem, index) in this.getBoard.lists"
            :key="index"
            :board="getBoard"
            :list="listItem"
          ></TaskList>
        </div>
      </div>

      <Popup
        :item="Selected_item"
        :list="Selected_list"
        :board="Selected_board"
        :users="getUsers"
      />
    </div>
  </div>
</template>
<script>
import TaskList from "@/components/Lists/TaskList";
import AppHeader from "@/components/AppHeader";
import draggable from "vuedraggable";
import Popup from "@/components/ProjectPopup/Popup.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "Board",
  components: {
    TaskList,
    draggable,
    AppHeader,
    Popup,
  },
  data() {
    return {
      Selected_item: {},
      Selected_list: {},
      Selected_board: {},
    };
  },
  beforeCreate() {
    this.$store.dispatch("fetchProject", { boardId: this.$route.params.id });
    this.$store.dispatch("fetchProjects");
    this.$store.dispatch("fetchUsers");
  },
  props: ["item", "list", "board"],
  computed: {
    ...mapGetters({
      boards: "allBoards",
      isLoading: "isLoading",
      getUsers: "getUsers",
      getBoard: "getBoard",
      unarchivedLists: "unarchivedLists",
    }),
    getDragOptions() {
      return {
        animation: "200",
        ghostClass: "ghost",
        handle: ".heading",
        disabled: !this.shouldAllowListOrder,
        group: "kanban-board-lists",
      };
    },
    param() {
      return this.$route.params.id;
    },
    shouldAllowListOrder() {
      return this.isDesktop || this.isTablet;
    },
    getBoard() {
      // var newList=[]
      // var newBoard=this.boards.find((b) => b._id == this.param)
      // newBoard.lists.forEach(element => {
      //   if(element.status=="active"){
      //     newList.push(element)
      //   }
      // });
      // this.boards.lists=newList
      // return this.boards
      // return this.boards.find((b) => b._id == this.param)
      if (this.boards.length > 0)
        return this.boards.find((b) => {
          if (b.lists && b.lists.length > 0) {
            return (
              b._id == this.param &&
              b.lists.some((list) => list.status === "active")
            );
          } else {
            return b._id == this.param;
          }
        });
    },
    getBoardUnarchived() {
      var newList = [];
      var newBoard = this.boards.find((b) => b._id == this.param);
      newBoard.lists.forEach((element) => {
        if (element.status == "active") {
          newList.push(element);
        }
      });
      this.boards.lists = newList;
      return this.boards;
    },
    //   getLists() {
    //   return this.lists.filter((b) => b.status === "active");
    // },
    lists: {
      get() {
        return this.boards.find((b) => b._id == this.param) &&
          this.boards.find((b) => b._id == this.param).lists != undefined
          ? this.boards.find((b) => b._id == this.param).lists
          : [];
      },
      async set(value) {
        await this.reorderTaskLists({
          boardId: this.param,
          lists: value,
        });
      },
    },
  },
  methods: {
    async changeOrder(event) {
      var objectToUpdate = this.getBoard.lists.find(
        (item) => item.index === event.moved.newIndex
      );
      objectToUpdate.index = event.moved.oldIndex;
      event.moved.element.index = event.moved.newIndex;
      // var objectToUpdate2=
      await this.reorderTaskLists({
        obj1: objectToUpdate,
        obj2: event.moved.element,
        boradId: this.param,
      });
    },
    OnSelection(value, board,list,index) {
      console.log("value", list)
      this.Selected_item = value;
      this.Selected_list = list[index]
      this.Selected_board = board._id
    },
    ...mapActions({
      reorderTaskLists: "reorderTaskLists",
      setActiveTaskBoard: "setActiveTaskBoard",
    }),
  },
  created() {
    if (this.getBoard) {
      this.setActiveTaskBoard({
        board: this.getBoard,
      });
    }
  },
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .projets .phone-ver {
    display: -webkit-box;
  }
}
/* @media screen and (max-width: 400px) {
  .projets .phone-ver {
      display: flex;
  }
  
} */

.projets {
  overflow-x: scroll;
  height: 525px;
}
/* width */
.projets::-webkit-scrollbar {
  width: 2px;
  opacity: 0.5;
}

/* Track */
.projets::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.projets::-webkit-scrollbar-thumb {
  background: #5600ffc9;
  border-radius: 10px;
}

/* Handle on hover */
.projets::-webkit-scrollbar-thumb:hover {
  background: #9600ff;
}
@media (max-width: 360px) {
  /* .phone-ver {
    width: 400%;
    margin-left: 2px;
  } */
}
</style>
