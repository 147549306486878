<template>
  <div class="col-md-4 col-lg-3 col-sm-12 list-column list-width"  v-if="list.status =='active'"
>
    <div
      class="heading"
      style="background-color: #9600ff !important; color: #fff"
    >
      <!-- :style="{ backgroundColor: list.headerColor }" -->
      <h4 class="heading-text text-center" style="color: #fff; padding: 1.5rem">
        {{ list.name }}
      </h4>
      <TaskListActions
        :board="board"
        :list="list"
        style="margin-right: 15px"
      ></TaskListActions>
    </div>
    <div class="cards cards-list">
      <draggable
        v-model="items"
        v-bind="dragOptions"
        @change="changeOrder($event, items)"
      >
    
        <TaskListItem
          v-b-modal.modal-center
          v-for="item in items"
          :item="item"
          :list="list"
          :board="board"
          :key="item._id"
          @click.native="
            GetListitem(item, list, board);
            emitToParent(item);
          "
          @item-edited="itemEdited"
          @item-cancelled="itemCancelled"
          @item-editing="itemEditing"
        ></TaskListItem>
      </draggable>
      <TaskListItem
        class="fixed-card"
        :item="defaultItem"
        :list="list"
        :board="board"
        @item-edited="itemEdited"
        @item-cancelled="itemCancelled"
        @item-editing="itemEditing"
      ></TaskListItem>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import TaskListActions from "@/components/Lists/TaskListActions";
import TaskListItem from "@/components/Items/TaskListItem";
import Popup from "@/components/ProjectPopup/Popup.vue";

import { mapActions } from "vuex";
export default {
  components: {
    TaskListItem,
    TaskListActions,
    Popup,
    Draggable,
  },
  props: ["board", "list"],
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    defaultItem() {
      return {
        id: "",
        text: "",
      };
    },
    dragOptions() {
      return {
        animation: "200",
        ghostClass: "ghost",
        group: "kanban-board-list-items",
        disabled: this.isEditing || !this.shouldAllowTaskItemsReorder,
      };
    },
    items: {
      get() {
        return this.list.items;
      },
      set(reorderedListItems) {
        const payload = {
          boardId: this.board.id,
          listId: this.list.id,
          items: reorderedListItems,
        };
        this.reorderTaskListItems(payload);
      },
    },
    shouldAllowTaskItemsReorder() {
      return this.isDesktop || this.isTablet;
    },
  },

  methods: {
    async changeOrder(event, items) {
      var obj3=""
      var fromList=""
      var fromIndex= ""
      if (event.moved) {
        console.log("event move", event, items, this.list,this.$route.params.id);
        var objectToUpdate = items.find(
          (item) => item.index === event.moved.newIndex
        );
        objectToUpdate.index = event.moved.oldIndex;
        event.moved.element.index = event.moved.newIndex;
        // var objectToUpdate2=
        await this.reorderTaskListItems({
          obj1: objectToUpdate,
          obj2: event.moved.element,
          boradId: this.$route.params.id,
          listId: this.list._id,
        });
      }else if(event.added){
        console.log("event add", event, items, this.list,this.$route.params.id);
        console.log("iam here");
        localStorage.setItem("idBoardRemove", this.list._id);
        localStorage.setItem("indexBoardRemove", event.added.newIndex);
      }else if(event.removed){
        console.log("event remove", event, items, this.list,this.$route.params.id);
        console.log("iam here");
        obj3 = event.removed.element._id
        fromList = this.list._id
        fromIndex=event.removed.oldIndex
         await this.reorderTaskListToListItems({
           boradId: this.$route.params.id,
           obj3:obj3,
           toList:localStorage.getItem("idBoardRemove"),
           toIndex:localStorage.getItem("indexBoardRemove"),
           fromList: fromList,
           fromIndex:fromIndex
        });
      }
    },
    ...mapActions({
      reorderTaskListItems: "reorderTaskListItems",
      reorderTaskListToListItems:"reorderTaskListToListItems"
    }),
    GetListitem(itemid, listid, boardid) {
      console.log("ITEM", itemid);
      console.log("LIST", listid);
      console.log("BOARD", boardid);
    },
    emitToParent(param) {
      this.$emit("SelectItem", param);
    },
    itemEditing() {
      this.isEditing = true;
    },
    itemEdited() {
      this.isEditing = false;
    },
    itemCancelled() {
      this.isEditing = false;
    },
  },
  created() {
    this.emitToParent();
  },
};
</script>
