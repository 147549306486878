<template>
  <div id="app">
    <h5 class="todo-title" style="padding-left: 12px">
      <feather-icon style="width: auto; height: 15px" icon="CheckSquareIcon" />
      <b>Todo</b>
    </h5>
    <ul class="list-unstyled" style="padding-left: 24px">
      <li v-for="(task, index) in todo" :key="index">
        <b-form-checkbox
          v-model="task.done"
          name="checkbox-1"
          class="mt-1"
          v-on:change="updateTodo(task)"
        >
          <p class="tasksText">
            <span :class="{ done: task.done }">
              {{ task.text }}
            </span>
          </p>
        </b-form-checkbox>
        <div class="subItem-position row">
          <div class="col-1">
            <span class="span-assigner"
              ><feather-icon icon="UserPlusIcon"
            /></span>
          </div>
          <div class="col-4">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :close-on-select="true"
              :options="Assigned"
              label="name"
              input-id="add-guests"
              class="select-subItem"
              v-model="userSelected[index]"
              v-on:input="updateTodo(task)"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </div>
          <div class="col-1">
            <span class="span-assigner subItem-icon-clock"
              ><feather-icon icon="ClockIcon"
            /></span>
          </div>

          <div class="col-4">
            <flat-pickr
              v-on:change="updateTodo(task)"
              v-model="task.deadline"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
            />
          </div>
          <div class="col-1">
            <span
              class="span-assigner subItem-icon-clock"
              style="cursor: pointer"
              v-on:click="deleteTodo(task)"
              ><feather-icon icon="TrashIcon"
            /></span>
          </div>
        </div>
      </li>
    </ul>
    <p>
      <b-input-group class="booking-preprend">
        <b-form-input
          type="text"
          v-model="todoText"
          placeholder="add new todo here"
          autofocus
          trim
        />

        <b-input-group-append>
          <button v-on:click="addTodo()" class="btn btn-primary btn-sm">
            Add
          </button>
        </b-input-group-append>
      </b-input-group>
    </p>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  BForm,
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";

export default {
  props: ["todo", "item", "board", "list"],
  data() {
    return {
      todoText: "",
      Assigned: [],
      userSelected: [],
    };
  },
  components: {
    flatPickr,
    vSelect,
    BFormInput,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BBadge,
    BFormCheckbox,
  },
  created() {
    this.getUsers();
    this.getSelectedUser();
  },
  methods: {
    getSelectedUser() {
      console.log("el todo", this.todo);
      this.todo.forEach((element) => {
        this.userSelected.push(element.assignedTo[0]);
      });
    },
    getUsers() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/users?id=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log("data", response);
          response.data.data.friendList.forEach((element) => {
            this.Assigned.push({
              name: element.name,
              id: element._id,
            });
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    ...mapActions({
      saveTaskListItemTodo: "saveTaskListItemTodo",
      UpdateTaskListItemTodo: "UpdateTaskListItemTodo",
      deleteTaskListItemTodo: "deleteTaskListItemTodo",
    }),
    ...mapGetters({
      getBoard: "getBoard",
    }),
    addTodo() {
      var newTodo = this.todoText.trim();
      if (newTodo.length === 0 || !newTodo) {
        return;
      }
      let createTodo = { text: newTodo, done: false };
      this.todo.push(createTodo);
      this.saveTaskListItemTodo({
        boardId: this.getBoard()._id,
        listId: this.item.projectList,
        itemId: this.item._id,
        todo: createTodo,
      });
      this.todoText = "";
    },
    updateTodo(task) {
      // this.userSelected.forEach(element => {
      //   task.assignedTo.push(element.id)
      // });
      console.log("task", task);
      if(this.userSelected && this.userSelected.length > 0){
        task.assignedTo = this.userSelected[0].id;
      }
      var idTodo = task._id;
      if(task._id){
        delete task._id;
      }
      this.UpdateTaskListItemTodo({
        boardId: this.getBoard()._id,
        listId: this.item.projectList,
        itemId: this.item._id,
        todoId: idTodo,
        todo: task
      });
    },
    deleteTodo(task) {
      this.deleteTaskListItemTodo({
        boardId: this.getBoard()._id,
        listId: this.item.projectList,
        itemId: this.item._id,
        todoId: task._id,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.tasksText span {
  display: block;
}
.span-assigner {
  color: #9600ff;
  font-size: 12px;
}
.subItem-position {
  margin-top: -10px;
  margin-bottom: 20px;
}
.subItem-position span {
  margin-left: 5px;
}
.done {
  text-decoration: line-through;
  color: grey;
}
/* .select-subItem{
  height: 0px;
  width: 0px;
  margin-top:-15px;
}
.select-subItem .vs__dropdown-toggle{
  height:0px;
  width: 0px;
}
.subItem-icon-clock{
  margin-left: 50px!important;
  margin-top:-10px;
}  */
</style>